import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export function CoverImage() {
  return <Image src="/frontpage/skogshorn.jpg" mdxType="Image" />;
}
export const _frontmatter = {};
const layoutProps = {
  CoverImage,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 className="px-2 text-center">Visit Gol</h1>
    <h3 className="text-center text-lg">
  Et løsningsforslag for å digitalisere turismen i Gol
    </h3>
    <br />
    <CoverImage mdxType="CoverImage" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Løsninger fra Trailguide er allerede tatt i bruk noe på Golsfjellet, men det
  er ytret ønske fra flere om å ha en felles løsning for hele Gol kommune. Her
  er et forslag til løsning.
    </div>
    <br />
    <h4>{`Plassere ut QR-koder der gjester og besøkende ferdes`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Man kan plassere ut en QR-kode alle steder der turister ferdes i Gol kommune.
  Turistkontoret, hoteller, overnattingssteder, campingplasser,
  parkeringsplasser, kaféer, restauranter, butikker osv.
    </div>
    <Image src="website/trailguide-qr-display.jpg" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Gjester skanner QR-koden med mobiltelefonen sin og får umiddelbar tilgang til
  digital turinformasjon, kart, ruter og GPS-navigasjon rett i telefonen. Ingen
  nedlastning, installasjon eller registrering kreves.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Kodene som stilles ut de ulike stedene kan tilpasses til å vise turer som er
  aktuelle for det spesifikke området og type gjester. Det kan også lages én
  felles QR-kode som viser alle tilgjengelige turer i Gol kommune.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Alle medlemsbedrifter i Visit Gol som ønsker å lage en egen QR-kode kan gjøre
  dette, men de som ønsker å få en fra turistkontoret kan også få dette.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Noen av tur- og aktivitetsmulighetene som kan vises kan være stisykling,
  grussykling, vandring, fatbike, toppturer, fiske, badeplasser, padling,
  langrenn, alpint, klatring, discgol, ridning, skateparker, lekeplasser, osv.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Det er enkelt for gjester og besøkende å få tilgang til turinformasjon. Det
  blir enkelt for dem som står på andre siden av resepsjonsdisken å vise frem
  turer og aktiviteter i Gol. Man kan ha en hel "meny" av QR-koder med tilgang
  til ulike type turer.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  QR-kodene kan benyttes på trykt materiell som turkart som gis ut til gjester.
  De kan brukes på kart og skilt som står utstilt rundt om i kommunen. De kan
  benyttes på utleiesykler.
    </div>
    <br />
    <h4>{`Vise turmuligheter på websider`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  I stedet for å redigere hver enkelt webside som er tilgjengelig for gjester og
  besøkende i Gol (visit gol, hoteller, camping osv) kan man enkelt hente
  turinformasjon fra Trailguide og vise dette direkte i egne websider.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Turinformasjonen kan være kuratert, oppdatert og administrert av
  turistkontoret slik at hver enkelt aktør slipper å drive med oppdatering.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Denne muligheten vil være tilgjengelig for alle aktører i Gol som ønsker det.
    </div>
    <br />
    <h4>{`Egen tur-app for Visit Gol`}</h4>
    <Image className="float-right w-56 ml-12" src="website/trailguide-iphone-sandbox-icons.jpeg" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Det kan settes opp en egen tur-app for Gol som viser alt av turer og
  aktiviteter tilgjengelig i Gol (men ingenting annet). Denne kan aksesseres
  direkte via en QR-kode eller link og kan enkelt installeres på telefonen til
  brukerne.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Gjester og besøkende som benytter appen (eller en QR-kode) kan gi
  tilbakemeldinger om hvor godt de liker turene eller gi tips om forholdene.
  Dermed kan turistkontoret få tilgang til fersk informasjon og justere
  tilgjengelige turer eller turtips.
    </div>
    <br />
    <h4>{`Overnattingssteder, spisesteder og sportsbutikker`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  De medlemsbedriftene som ønsker det kan vises frem i kartet og i
  turbeskrivelser som er in nærheten av bedriften. Da kan besøkende finne frem
  til mat og drikke, overnattinger, utleie, sportsbutikker osv.
    </div>
    <br />
    <h4>{`Synlighet i Trailguide`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Gol som turistdestinasjon og resorts rundt om i destinasjonen kan gjøres
  synlig i Trailguide-appen som brukes av terrengsyklister på utkikk etter bra
  stier. Dermed kan andre aktiviteter enn terrengsykling synliggjøres via denne
  appen.
    </div>
    <br />
    <h4>{`Hjelp til oppsett og innhold`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Vi kan være behjelpelig med å sette opp QR-koder, legge inn manglende turer i
  vår database om data allerede er tilgjengelig, og hjelpe til med å legge inn
  på websider.
    </div>
    <br />
    <br />
    <br />
    <ByLine author="Bjørn Jarle Kvande" mdxType="ByLine" />
    <div className="text-sm mt-3 text-gray-700 leading-6">
  bjorn.jarle.kvande
  <span className="hidden">If you see this, apply for a job</span>@<span className="hidden">
    as a programmer at Trailguide
  </span>
  trailguide.no
  <br />
  +47 916 36 159
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      